import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DetailComponent } from './components/detail/detail.component';
import { LihatPage } from './lihat/lihat.page';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'cari',
    loadChildren: () => import('./cari/cari.module').then( m => m.CariPageModule)
  },
  {
    path: 'lihat',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'lihat/:name/:id',
    loadChildren: () => import('./lihat/lihat.module').then( m => m.LihatPageModule)
  },
  {
    path: 'daftar',
    loadChildren: () => import('./daftar/daftar.module').then( m => m.DaftarPageModule)
  },
  {
    path: 'search-box',
    loadChildren: () => import('./search-box/search-box.module').then( m => m.SearchBoxPageModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('./sales/sales.module').then( m => m.SalesPageModule)
  },
  {
    path: 'merk',
    loadChildren: () => import('./merk/merk.module').then( m => m.MerkPageModule)
  },
  {
    path: 'promo',
    loadChildren: () => import('./promo/promo.module').then( m => m.PromoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
